<template>
  <div
    class="d-flex"
    :style="tabValue == 'tab1' ? '' : 'background-color: #fff;'"
  >
    <div>
      <img :src="src" />
    </div>
    <div class="title_Tabs">
      <el-tabs
        v-model="tabValue"
        :class="tabValue == 'tab1' ? 'color-tab-one' : 'color-tab-two'"
        @tab-click="toOtherPage"
      >
        <el-tab-pane
          :key="item.name"
          v-for="item in tabList"
          :label="item.name"
          :name="item.key"
          @tab-click="toOtherPage(item.key)"
        >
          <!-- {{item.name}} -->
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/Image/logo001.png";
export default {
  data() {
    return {
      src: logo,
      tabValue: "tab1",
      tabList: [
        {
          name: "首页",
          key: "tab1",
        },
        {
          name: "关于旷真",
          key: "tab2",
        },
        {
          name: "旷真知产",
          key: "tab3",
        },
        {
          name: "旷真应收款",
          key: "tab4",
        },
        {
          name: "旷真民生",
          key: "tab5",
        },
        {
          name: "新闻动态",
          key: "tab6",
        },
        {
          name: "加入旷真",
          key: "tab7",
        },
        {
          name: "联系我们",
          key: "tab8",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    toOtherPage(key) {
      this.tabValue = key.name;
      if (key.name != "tab1") {
        this.src = "https://f.kzspp.com/cms/new/theme/kz/images/logo002.png";
      } else {
        this.src = logo;
      }
      switch (key.name) {
        case "tab1":
          this.$router.push("/index");
          break;
        case "tab2":
          this.$router.push("/about");
          break;
        case "tab3":
          this.$router.push("/intellectualProperty");
          break;
        case "tab4":
          this.$router.push("/business");
          return;
        case "tab5":
          this.$router.push("/ms");
          return;
        case "tab6":
          this.$router.push("/news");
          return;
        case "tab7":
          this.$router.push("/joinUs");
          return;
        case "tab8":
          this.$router.push("/contactUs");
          return;
      }
    },
  },
  watch: {
    "$route.path": function (newVal, oldVal) {
      if (newVal == "/index") {
      } else {
        this.src = "https://f.kzspp.com/cms/new/theme/kz/images/logo002.png";
        switch (newVal) {
          case "/about":
            this.tabValue = "tab2";
            break;
          case "/intellectualProperty":
            this.tabValue = "tab3";
            break;
          case "/business":
            this.tabValue = "tab4";
            break;
          case "/ms":
            this.tabValue = "tab5";
            break;
          case "/news":
            this.tabValue = "tab6";
            break;
          case "/joinUs":
            this.tabValue = "tab7";
            break;
          case "/contactUs":
            this.tabValue = "tab8";
            break;
          case "/newsMsg":
            this.tabValue = "tab6";
            break;
          case "/talentGrowth":
            this.tabValue = "tab7";
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
}
.title_Tabs {
  padding-left: 110px;
  letter-spacing: 2px;
  font-size: 16px;
}
.title_Tabs >>> .el-tabs__nav-wrap::after {
  position: static !important;
}
.title_Tabs,
.el-tabs,
.title_Tabs >>> .el-tabs__header,
.title_Tabs >>> .is-top {
  height: 48px;
}
.color-tab-one >>> .el-tabs__item {
  color: #fff;
}
.color-tab-two >>> .el-tabs__item {
  color: #000;
}
.title_Tabs >>> .el-tabs__item {
  font-size: 16px;
  line-height: 58px;
}
.title_Tabs >>> .is-active {
  position: relative;
  /* color: #f44336; */
}
.title_Tabs >>> .is-active::after {
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: 80%;
  background-color: #f44336;
  content: "";
  z-index: 999999;
}
.title_Tabs >>> .el-tabs__item:nth-child(2).is-active::after {
  /* width: ; */
  left: 25%;
}
.title_Tabs >>> .el-tabs__active-bar.is-top {
  background: none !important;
}
.title_Tabs >>> .el-tabs__item:hover {
  color: #f44336;
  cursor: pointer;
}
</style>
