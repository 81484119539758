<template>
  <div class="d-flex footer-content justify-center">
    <div class="la-footerLeft d-flex">
      <div v-for="(item, index) in list" :key="index" class="img-item">
        <el-tooltip placement="top">
          <div slot="content">
            <img
              :src="item.link"
              style="width: 150px; height: 150px"
              class="img-style"
            />
          </div>
          <el-button style="padding: 0"
            ><img :src="item.link" class="img-style"
          /></el-button>
        </el-tooltip>

        <div>{{ item.name }}</div>
      </div>
    </div>
    <div class="footer-right">
      <div>
        总部地址：长沙市芙蓉区解放西路188号国金中心T1号写字楼40层09-16单元
      </div>
      <div>
        联系电话：<span class="la-fontSize18">0731 - 8980 2222 旷真民生</span>
      </div>
      <div>© 2003-2022 KUANGZHEN.COM.CN 版权所有</div>
      <div>
        <a
          href="https://beian.miit.gov.cn"
          target="_blank"
          style="color: rgb(141, 141, 141)"
          >湘ICP备16010646号"></a
        >
      </div>
      <div>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43011102001805"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
        >
          <img src="http://www.beian.gov.cn/img/ghs.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            湘公网安备 43011102001805号
          </p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import linkOne from "@/assets/Image/mini.jpg";
import linkTwo from "@/assets/Image/mic.jpg";
import linkThree from "@/assets/Image/bdfc.png";
export default {
  name: "Footer",
  data() {
    return {
      list: [
        {
          link: linkOne,
          name: "旷真小程序",
        },
        {
          link: linkTwo,
          name: "旷真MIC",
        },
        {
          link: linkThree,
          name: "旷真应收款",
        },
        {
          link: "https://f.kzspp.com/cms/new/theme/kz/images/kzzc.jpg",
          name: "旷真知产",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.d-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.img-item {
  padding: 0 15px;
}
.footer-content {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.la-fontSize18 {
  font-size: 18px;
  font-weight: bold;
}
.la-footerLeft {
  width: 530px;
  border-right: 1px solid rgb(141, 141, 141);
  padding: 0 20px;
  vertical-align: middle;
}
.img-style {
  width: 100px;
  height: 100px;
  background: #ffffff;
}
.footer-right {
  width: 510px;
  text-align: start;
  line-height: 26px;
  padding: 0 20px;
}
</style>
