import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router';
// 引入状态管理
import store from './vuex/store';

// rem
// import "./utils/rem";

// 公共样式
import './sass/public.scss'

// ElementUI组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 高德地图
import AMap from 'vue-amap'

// AmapVue.config.key = '22d29933d169a5ca9d02c9e3589cb3b5'
// Vconsole
// import Vconsole from 'vconsole';
// new Vconsole();

AMap.initAMapApiLoader({
  key: "22d29933d169a5ca9d02c9e3589cb3b5", // 高德地图 key
  plugin: [
    "AMap.Autocomplete",
    "AMap.PlaceSearch",
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PolyEditor",
    "AMap.CircleEditor",
    "AMap.Geolocation",
    "AMap.Geocoder",
  ],
  // 默认高德 sdk 版本为 1.4.4
  // v: "1.4.10",
});
Vue.use(AMap)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store, //使用store vuex状态管理
  render: h => h(App),
}).$mount('#app')
